import React from "react";

const SliderGovernance = () => {
    return (
        <>
            <div className="text-center governance-slider">
                <h2 className="display-2">Board Members</h2>
                <div className="overlay"></div>
            </div>
        </>
    );
};

export default SliderGovernance;