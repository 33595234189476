import React from "react";

const SliderPublication = () => {
    return (
        <>
            <div className="text-center publication-slider">
                <div className="center-area">
                    <h2 className="display-2">ADI Publications</h2>
                </div>
                <div className="overlay"></div>
            </div>
        </>
    );
};

export default SliderPublication;