import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faMobile, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

interface HeaderProps {
    projectName: string
}
const Header: React.FC<HeaderProps> = ({projectName}) => {
    return (
        <>
            <div className="container header">
                <div className="topbar">
                    <div className="row">
                        <div className="col-xl-6 col-lg-3 col-sm-3 col-3">
                            <div className="icon">
                                <Link to="/">
                                    <img src="./image/logo.png" alt="n/a" className="img-fluid"/>
                                </Link>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-sm-3 col-3">
                            <div className="icon topbar-grid d-lg-flex d-block align-items-center">
                                <div className="font-icon">
                                    <FontAwesomeIcon icon={faPhone}/>
                                </div>
                                <div className="d-md-block d-none">
                                    <Link to="#0" target="_blank">+02-9861412</Link>
                                    <p>ADI Hotline</p>                                    
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-sm-3 col-3">
                            <div className="icon topbar-grid d-lg-flex d-block align-items-center">
                                <div className="font-icon">
                                    <FontAwesomeIcon icon={faMobile}/>
                                </div>
                                <div className="d-md-block d-none">                                
                                    <Link to="#0" target="_blank">01321134960</Link>
                                    <p className="small">24/7 Client Support</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-sm-3 col-3">
                            <div className="icon topbar-grid d-lg-flex d-block align-items-center">
                                <div className="font-icon">
                                    <FontAwesomeIcon icon={faEnvelope}/>
                                </div>
                                <div className="d-md-block d-none">
                                    <Link to="#0" target="_blank">adi.bd.org@gmail.com</Link>
                                    <p>Online always open</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Header;