import React from "react";
import SliderPublication from "../../components/PageComponents/SliderPublication";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
const PublicationPage = () => {
    return (
        <>
            <SliderPublication />
            <div className="container py-3">
                <div className="publication-section-header my-5">
                    <div className="text-center py-3">
                        <h2 className="display-3 adi-text-primary"> 
                            <span>ADI</span> & Future of Human Beeings
                        </h2>
                        <p className="lead">
                            From banking and insurance to wealth management and securities <br/> distribution, we dedicated financial services the teams serve
                        </p>
                    </div>                    
                </div>
                <div className="card-area">
                        <div className="row">
                            <div className="col-md-6">
                                <img src="./image/publication1.webp" alt="n/a" className="img-fluid rounded" />
                            </div>
                            <div className="col-md-6">
                                <h3 className="card-title">Alternative Development Initiatives (ADI)</h3>                                
                                <p className="card-text border-0 p-0">
                                    Bangladesh is one of the world’s most densely populated developing countries. The vast majority of people in this country live in villages. The majority of these rural people live in abject poverty. The organization’s main goals are to alleviate their poverty by involving them in various development projects and to provide them with monetary and technical assistance collectively in order to improve their standard of living.						
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="lead adi-text-secondary">Care About Us Social Business</div>
                                <h3 className="display-5 adi-text-primary">Where Learning Begin</h3>
                                <p className="card-text2">
                                    Building a strong sense of community in Greenville school Community - Building Approaches					
                                </p>
                                 <Link to="/publications" className="card-link">
                                    <h6> Best Security Services</h6>
                                    <p className="card-text3">
                                        Our best-in-class WordPress solution, with additions optiz ation to make					
                                    </p>
                                </Link>
                                 <Link to="/publications" className="card-link">
                                    <h6>Online info Services</h6>
                                    <p className="card-text3">
                                        Our best-in-class WordPress solution, with additions optiz ation to make					
                                    </p>
                                </Link>
                                <button className="btn adi-btn-secondary">Learn More <FontAwesomeIcon icon={faAngleRight}></FontAwesomeIcon></button>
                            </div>
                            <div className="col-md-6">
                                <img src="./image/publication1.webp" alt="n/a" className="img-fluid rounded" />
                            </div>
                        </div>
                </div>
                <div className="publication-card-area">
                    <div className="text-center py-5">
                        <div className="lead adi-text-secondary fw-bold">Our ekit SCHOOL courses</div>
                        <h3 className="display-3 adi-text-primary">Want to learn today?</h3>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="pablication-card">
                                <div className="card-image">
                                    <img src="./image/publication3.webp" alt="n/a" className="img-fluid" />
                                </div>
                                <div className="card-content-area">
                                    <h4>Your Complete Guide to Photography</h4>
                                    <p>A small river named Duden flows by their place and supplies it with the necessary...</p>
                                    <Link to='#0' className="btn btn-sm border">Read More</Link>
                                </div>  
                            </div>
                        </div>
                        <div className="col-md-4">
                        <div className="pablication-card">
                            <div className="card-image">
                                <img src="./image/publication4.webp" alt="n/a" className="img-fluid" />
                            </div>
                            <div className="card-content-area">
                                <h4>Learn Python – Interactive Python</h4>
                                <p>
                                    A small river named Duden flows by their place and supplies it with  the necessary ... 
                                </p>
                                <Link to='#0' className="btn btn-sm border">Read More</Link>
                            </div>
                        </div>
                        </div>
                        <div className="col-md-4">
                            <div className="pablication-card">
                                <div className="card-image">
                                    <img src="./image/publication5.webp" alt="n/a" className="img-fluid" />
                                </div>
                                <div className="card-content-area">
                                    <h4>Introduction to Edu_Learn LMS Plugin</h4>
                                    <p>A small river named Duden flows by their place and supplies it with the necessary...</p>
                                    <Link to='#0' className="btn btn-sm border">Read More</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="pablication-card">
                                <div className="card-image">
                                    <img src="./image/publication6.webp" alt="n/a" className="img-fluid" />
                                </div>
                                <div className="card-content-area">
                                    <h4>Your Complete Guide to Photography</h4>
                                    <p>A small river named Duden flows by their place and supplies it with the necessary...</p>
                                    <Link to='#0' className="btn btn-sm border">Read More</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="pablication-card">
                                <div className="card-image">
                                    <img src="./image/publication7.webp" alt="n/a" className="img-fluid" />
                                </div>
                                <div className="card-content-area">
                                    <h4>Learn Python – Interactive Python</h4>
                                    <p>A small river named Duden flows by their place and supplies it with the necessary ... </p>
                                    <Link to='#0' className="btn btn-sm border">Read More</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="pablication-card">
                                <div className="card-image">
                                    <img src="./image/publication8.webp" alt="n/a" className="img-fluid" />
                                </div>
                                <div className="card-content-area">
                                    <h4>Introduction to Edu_Learn LMS Plugin</h4>
                                    <p>
                                        A small river named Duden flows by their place and supplies it with the necessary... 
                                    </p>
                                    <Link to='#0' className="btn btn-sm border">Read More</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        </>
    );
};

export default PublicationPage;