import React  from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { faMobile, faEnvelope, faLocationArrow } from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
    return (
        <>
        <div className="footer">
            <div className="py-0">
                <div className="footer-top container text-white">
                    <div className="row py-5">
                        <div className="col-md-4 col-lg-4 col-sm-12">
                            <h4 className="text-white">Alternative Development Initiatives (ADI)</h4>
                            <p>
                                <FontAwesomeIcon icon={faLocationArrow} />
                                House: 58 (4th Floor), Road: 03, Block: B, Niketon, Gulshan -01, Dhaka-1212, Bangladesh.
                            </p>
                            <p><FontAwesomeIcon icon={faMobile} /> +02-9861412</p>
                            <p><FontAwesomeIcon icon={faEnvelope} /> di.bd.org@gmail.com</p>
                        </div>
                        <div className="col-md-2 col-lg-2 col-sm-12">
                            <h4 className="text-white">Pages</h4>
                            <li><a href="#0">About Us</a></li>
                            <li><a href="#0">Programs</a></li>
                            <li><a href="#0">Publications</a></li>
                            <li><a href="#0">Governance</a></li>
                        </div>
                        <div className="col-md-2 col-lg-2 col-sm-12">
                            <h4 className="text-white">Important Links</h4>
                            <li><a href="#0">Privacy Policy</a></li>
                            <li><a href="#0">Career</a></li>
                            <li><a href="#0">Terms & Conditions</a></li>                          
                        </div>
                        <div className="col-md-4 col-lg-4 col-sm-12">
                            <h4 className="text-white">Don't Miss out</h4>
                            <p>Don’t miss our future updates! Get Subscribed Today!</p>
                            <form className="inline d-flex">
                                <input type="search" className="form-control radius" placeholder="Subscribe Now !"/> &nbsp; 
                                <button className="btn adi-btn-secondary"><FontAwesomeIcon icon={faPaperPlane} /></button>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="container copyright text-center text-white py-2">
                    Copyright &copy; 2024 | All right reserved.
                </div>
            </div>
        </div>
        </>
    );
};

export default Footer;