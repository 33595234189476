import { faUser } from "@fortawesome/free-regular-svg-icons";
import { faCalendarAlt, faComment } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";

const SliderBlog = () => {
    return (
        <>
            <div className="text-center blog-slider">
                <div className="center-area">
                    <h2 className="display-1">Blog Post</h2> <br />
                    <h4>Alternative Development Initiatives (ADI)</h4>
                </div>
                <div className="overlay"></div>
            </div>
        </>
    );
};

export default SliderBlog;