import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope, faAddressBook, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faInstagram, faTwitter } from "@fortawesome/free-brands-svg-icons";

const ContactPage = () => {
    return (
        <>
            <div className="row m-0 p-0 adi-bg-secondary text-white contact-page-banner">
                <section className="col-10 mx-auto py-5  text-center">
                    <div className="my-3 fw-bold">Don’t wait any longer</div>
                    <div className="display-4">Alternative Development Initiatives (ADI)</div>
                    <div className="py-3">House: 58 (4th Floor), Road: 03, Block: B, Niketon, Gulshan -01, Dhaka-1212, Bangladesh.</div>
                    <div className="display-6 my-3">
                        <FontAwesomeIcon icon={faPhone} className="me-2"/>
                        02-9861412
                    </div>
                </section>
            </div>
            <section className="container mx-auto py-5">
                <div className="row">
                    <div className="col-6 contact-left">
                        <div className="p-5">
                            <h2 className="display-2 adi-text-primary">Let's work together...</h2>
                            <div className="text-align-left py-4">
                                <p>
                                    <FontAwesomeIcon icon={faEnvelope} className="me-2"/>
                                    <strong>Email:</strong> <a href="mailto:di.bd.org@gmail.com">di.bd.org@gmail.com</a>
                                </p>
                                <p>
                                    <FontAwesomeIcon icon={faPhone} className="me-2"/>
                                    <strong>Phone:</strong> <a href="tel:+88029861412">+88029861412</a>
                                </p>
                                <p>
                                    <FontAwesomeIcon icon={faAddressBook} className="me-2"/>
                                    <strong>Address:</strong> House: 58 (4th Floor), Road: 03, Block: B, Niketon,<br/>&nbsp; &nbsp; &nbsp; Gulshan -01, Dhaka-1212, Bangladesh.
                                </p>
                            </div>
                            <div className="contact-social">
                                <a href="#0" className="btn adi-btn-secondary mx-2">
                                    <FontAwesomeIcon icon={faFacebook} />
                                </a>
                                <a href="#0" className="btn adi-btn-secondary mx-2">
                                    <FontAwesomeIcon icon={faInstagram} />
                                </a>
                                <a href="#0" className="btn adi-btn-secondary mx-2">
                                    <FontAwesomeIcon icon={faTwitter} />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 contact-right">
                        <div className="p-5">
                            <h2 className="display-2 adi-text-primary">Send a Message</h2>
                            <form className="py-4">
                                <div className="form-group py-3">
                                    <label className="form-label">Email address</label>
                                    <input type="email" className="form-control" placeholder="Enter email"/>                                    
                                </div>
                                <div className="form-group py-1">
                                    <label className="form-label">Message</label>
                                    <textarea className="form-control row-5" rows={7} placeholder="Enter message"></textarea>
                                </div>
                                <button type="submit" className="btn adi-btn-secondary mt-3">
                                    <FontAwesomeIcon icon={faPaperPlane} className="me-2"/>Send Message
                                </button>
                            </form>
                        </div>                        
                    </div>
                </div>
            </section>  
            <div className="map-wrapper m-0 p-0">
                {/* // eslint-disable-next-line jsx-a11y/iframe-has-title */}
                <iframe className="m-0 p-0" title={"map"} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d280.7940027663358!2d90.41568169248505!3d23.775412444698627!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755c79cc5ece8b7%3A0xed0888d87caec94a!2sRd%2003%2C%20Dhaka%201212!5e1!3m2!1sen!2sbd!4v1728131397923!5m2!1sen!2sbd" width="100%" height="450"   loading="lazy"></iframe>                
            </div>
        </>
    );
};

export default ContactPage;