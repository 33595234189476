import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";
import { faBars, faTimes, faChevronDown } from "@fortawesome/free-solid-svg-icons";

interface MenuProps {
    linkUrl: string
}
const Menu: React.FC<MenuProps> = ({ linkUrl }) => {
    // usestate for toogle menu
    const [showMenu, setShowMenu] = React.useState(false);
    // function to change link url data 
    const changeLinkUrl = (url: string) => {
        setShowMenu(false);
        if (url === '/') {
            return;
        }
        window.location.href = url;
    }

    return (
        <>
            <div className="main-menu">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-9 col-lg-10">
                            <ul className={`menu ${showMenu ? 'active' : ''}`} >
                                <li className="d-block d-lg-none text-end text-white py-1" onClick={() => changeLinkUrl('/')}> <FontAwesomeIcon icon={faTimes} /></li>
                                {/* Regular Menu Items */}
                                <li className="menu-item"><Link to="/" onClick={() => changeLinkUrl('/')} className={`${linkUrl === '/' ? 'active' : ''}`}>Home</Link></li>
                                {/* Mega Menu Item */}
                                <li className="menu-item mega-menu">
                                    <Link to="/about" onClick={() => changeLinkUrl('/about')} className={`${linkUrl === '/about' ? 'active' : ''}`} style={{ pointerEvents: 'none' }}>About <FontAwesomeIcon icon={faChevronDown} /></Link>
                                    <div className="mega-menu-content">
                                        <div className="mega-menu-row">
                                            <div className="mega-menu-column">
                                                <h3>ABOUT US</h3>
                                                <ul>
                                                    <li><Link to="/about/1" style={{ pointerEvents: 'none' }}>(ADI) Background</Link></li>
                                                    <li><Link to="/about/2" style={{ pointerEvents: 'none' }}>Goal & objectives</Link></li>
                                                    <li><Link to="/about/3" style={{ pointerEvents: 'none' }}>How do we manage?</Link></li>
                                                    <li><Link to="/about/3" style={{ pointerEvents: 'none' }}>Whom we are affiliated?</Link></li>
                                                    <li><Link to="/about/3" style={{ pointerEvents: 'none' }}>Where we work?</Link></li>
                                                </ul>
                                            </div>
                                            <div className="mega-menu-column">
                                                <h3>OBJECTIVES</h3>
                                                <ul>
                                                    <li><Link to="/about/7" style={{ pointerEvents: 'none' }}>Goal & Objectives</Link></li>
                                                    <li><Link to="/about/8" style={{ pointerEvents: 'none' }}>Working Area</Link></li>
                                                    <li><Link to="/about/9" style={{ pointerEvents: 'none' }}>Area coverage of ADI program</Link></li>
                                                </ul>
                                            </div>
                                            <div className="mega-menu-column">
                                                <h3>LEGAL STATUS</h3>
                                                <ul>
                                                    <li><Link to="/about/7" style={{ pointerEvents: 'none' }}>Organization’s Legal Status</Link></li>
                                                    <li><Link to="/about/8" style={{ pointerEvents: 'none' }}>Sideboards & Drawers</Link></li>
                                                    <li><Link to="/about/9" style={{ pointerEvents: 'none' }}>Lounge Chairs</Link></li>
                                                </ul>
                                            </div>
                                            <div className="mega-menu-column">
                                                <h3>ADI STAFF</h3>
                                                <ul>
                                                    <li><Link to="/about/7" style={{ pointerEvents: 'none' }}>Staff of ADI</Link></li>
                                                    <li><Link to="/about/8" style={{ pointerEvents: 'none' }}>Occasional Chairs</Link></li>
                                                    <li><Link to="/about/9" style={{ pointerEvents: 'none' }}>Daybeds & Chaises   </Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className="menu-item mega-menu">
                                    <Link to="/program" onClick={() => changeLinkUrl('/program')} className={`${linkUrl === '/program' ? 'active' : ''}`} style={{ pointerEvents: 'none' }}>Programs <FontAwesomeIcon icon={faChevronDown} /></Link>
                                    <div className="mega-menu-content">
                                        <div className="mega-menu-row">
                                            <div className="mega-menu-column">
                                                <h3>Category 3</h3>
                                                <ul>
                                                    <li><Link to="/program/1" style={{ pointerEvents: 'none' }}>Product 1</Link></li>
                                                    <li><Link to="/program/2" style={{ pointerEvents: 'none' }}>Product 2</Link></li>
                                                    <li><Link to="/program/3" style={{ pointerEvents: 'none' }}>Product 3</Link></li>
                                                </ul>
                                            </div>
                                            <div className="mega-menu-column">
                                                <h3>Category 4</h3>
                                                <ul>
                                                    <li><Link to="/program/7" style={{ pointerEvents: 'none' }}>Product 7</Link></li>
                                                    <li><Link to="/program/8" style={{ pointerEvents: 'none' }}>Product 8</Link></li>
                                                    <li><Link to="/program/9" style={{ pointerEvents: 'none' }}>Product 9</Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className="menu-item mega-menu">
                                    <Link to="/publication" onClick={() => changeLinkUrl('/publication')} style={{ pointerEvents: 'none' }} className={`${linkUrl === '/publication' ? 'active' : ''}`}>Publications <FontAwesomeIcon icon={faChevronDown} /></Link>
                                    <div className="mega-menu-content">
                                        <div className="mega-menu-row">
                                            <div className="mega-menu-column">
                                                <h3>Category 5</h3>
                                                <ul>
                                                    <li><Link to="/products/1" style={{ pointerEvents: 'none' }}>Product 41</Link></li>
                                                    <li><Link to="/products/2" style={{ pointerEvents: 'none' }}>Product 2</Link></li>
                                                    <li><Link to="/products/3" style={{ pointerEvents: 'none' }}>Product 3</Link></li>
                                                </ul>
                                            </div>
                                            <div className="mega-menu-column">
                                                <h3>Category 6</h3>
                                                <ul>
                                                    <li><Link to="/products/7" style={{ pointerEvents: 'none' }}>Product 65</Link></li>
                                                    <li><Link to="/products/8" style={{ pointerEvents: 'none' }}>Product 8</Link></li>
                                                    <li><Link to="/products/9" style={{ pointerEvents: 'none' }}>Product 9</Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className="menu-item mega-menu">
                                    <Link to="/governance" className={`${linkUrl === '/governance' ? 'active' : ''}`} style={{ pointerEvents: 'none' }}>Governance <FontAwesomeIcon icon={faChevronDown} /></Link>
                                    <div className="mega-menu-content">
                                        <div className="mega-menu-row">
                                            <div className="mega-menu-column">
                                                <h3>Category 1</h3>
                                                <ul>
                                                    <li><Link to="/governance/1" style={{ pointerEvents: 'none' }}>Product 71</Link></li>
                                                    <li><Link to="/governance/2" style={{ pointerEvents: 'none' }}>Product 2</Link></li>
                                                    <li><Link to="/governance/3" style={{ pointerEvents: 'none' }}>Product 3</Link></li>
                                                </ul>
                                            </div>
                                            <div className="mega-menu-column">
                                                <h3>Category 3</h3>
                                                <ul>
                                                    <li><Link to="/governance/7" style={{ pointerEvents: 'none' }}>Product 87</Link></li>
                                                    <li><Link to="/governance/8" style={{ pointerEvents: 'none' }}>Product 8</Link></li>
                                                    <li><Link to="/governance/9" style={{ pointerEvents: 'none' }}>Product 9</Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className="menu-item"><Link to="/contact" style={{ pointerEvents: 'none' }} onClick={() => changeLinkUrl('/contact')} className={`${linkUrl === '/contact' ? 'active' : '' }`}>Contact</Link></li>
                                <li className="menu-item"><Link to="/blog" style={{ pointerEvents: 'none' }} onClick={() => changeLinkUrl('/blog')} className={`${linkUrl === '/blog' ? 'active' : ''}`}>Blog</Link></li>
                            </ul>                        
                        </div>
                        <div className="col-xl-3 col-lg-2 col-md-12 col-sm-12 social-links d-flex align-items-center justify-content-end">
                            <button type="button" className="fs-20 btn text-white py-0 d-block d-lg-none" onClick={() => setShowMenu(!showMenu)} ><FontAwesomeIcon icon={faBars} /></button>
                            <Link to="#0" style={{ pointerEvents: 'none' }}><FontAwesomeIcon icon={faFacebook}/></Link>
                            <Link to="#0" style={{ pointerEvents: 'none' }}><FontAwesomeIcon icon={faTwitter}/></Link>
                            <Link to="#0" style={{ pointerEvents: 'none' }}><FontAwesomeIcon icon={faInstagram}/></Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Menu;
