import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import './relative/path/to/styles.css';


const SliderHome = () => {
    return (
        <>
            <div className="home-slider">
                <div className="text-center container">
                    <Swiper
                        speed={600}
                        parallax={true}
                        pagination={{
                        clickable: true,
                        }}
                        navigation={true}
                        modules={[Pagination, Navigation]}
                        className="mySwiper"
                    >
                        <div
                        slot="container-start"
                        className="parallax-bg"
                        style={{
                            backgroundImage: 'image/1.webp',
                        }}
                        data-swiper-parallax="-23%"
                        ></div>
                        <SwiperSlide>
                            <img src="image/1.webp" alt="" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src="image/2.webp" alt="" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src="image/3.webp" alt="" />
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>

        </>
    );
};

export default SliderHome;