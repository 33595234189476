import React from "react";
import SliderBlog from "../../components/PageComponents/SliderBlog";
import { faUser, faCalendarAlt, faComment } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
const BlogPage = () => {
    return (
        <>
            <SliderBlog />
            <div className="container">
                <div className="blog-card-area">
                    <div className="row">
                        <div className="col-md-4 my-3">
                            <div className="blog-card">
                                <div className="blog-card-image">
                                    <img src="./image/blog1.webp" alt="n/a" className="img-fluid" />
                                </div>
                                <div className="blog-card-text">
                                    <h4>ADI Fisheries</h4>
                                    <div className="post-meta">
                                        <ul className="m-0 p-0 d-flex flex-wrap">
                                            <li className="m-1"><FontAwesomeIcon icon={faUser}/> By admin-test</li>
                                            <li className="m-1"><FontAwesomeIcon icon={faCalendarAlt}/> October 26, 2023</li>
                                            <li className="m-1"><FontAwesomeIcon icon={faComment}/> Comment (0)</li>
                                        </ul>
                                    </div>
                                    <p>As an Agricultural country Bangladesh has enormous agricultural assets one of them is fisheries but...</p>
                                    <Link to="/blog/details" className="btn adi-btn-secondary">Read More</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 my-3">
                            <div className="blog-card">
                                <div className="blog-card-image">
                                    <img src="./image/blog2.webp" alt="n/a" className="img-fluid" />
                                </div>
                                <div className="blog-card-text">
                                    <h4>Agricultural101</h4>
                                    <div className="post-meta">
                                        <ul className="m-0 p-0 d-flex flex-wrap">
                                            <li className="m-1"><FontAwesomeIcon icon={faUser}/> By admin-test</li>
                                            <li className="m-1"><FontAwesomeIcon icon={faCalendarAlt}/> October 26, 2023</li>
                                            <li className="m-1"><FontAwesomeIcon icon={faComment}/> Comment (0)</li>
                                        </ul>
                                    </div>
                                    <p>As an Agricultural country Bangladesh has enormous agricultural assets one of them is fisheries but...</p>
                                    <Link to="/blog/details" className="btn adi-btn-secondary">Read More</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 my-3">
                            <div className="blog-card">
                                <div className="blog-card-image">
                                    <img src="./image/blog3.webp" alt="n/a" className="img-fluid" />
                                </div>
                                <div className="blog-card-text">
                                    <h4>Education Program</h4>
                                    <div className="post-meta">
                                        <ul className="m-0 p-0 d-flex flex-wrap">
                                            <li className="m-1"><FontAwesomeIcon icon={faUser}/> By admin-test</li>
                                            <li className="m-1"><FontAwesomeIcon icon={faCalendarAlt}/> October 26, 2023</li>
                                            <li className="m-1"><FontAwesomeIcon icon={faComment}/> Comment (0)</li>
                                        </ul>
                                    </div>
                                    <p>Education Program : Scholarship program for ultra-poor children Ultra poor are considered those who...</p>
                                    <Link to="/blog/details" className="btn adi-btn-secondary">Read More</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 my-3">
                            <div className="blog-card">
                                <div className="blog-card-image">
                                    <img src="./image/blog2.webp" alt="n/a" className="img-fluid" />
                                </div>
                                <div className="blog-card-text">
                                    <h4>Buniad-Ultra poor loans</h4>
                                    <div className="post-meta">
                                        <ul className="m-0 p-0 d-flex flex-wrap">
                                            <li className="m-1"><FontAwesomeIcon icon={faUser}/> By admin-test</li>
                                            <li className="m-1"><FontAwesomeIcon icon={faCalendarAlt}/> October 26, 2023</li>
                                            <li className="m-1"><FontAwesomeIcon icon={faComment}/> Comment (0)</li>
                                        </ul>
                                    </div>
                                    <p>Buniad This loan is provided to the people who are living at the extreme corner of poverty line in...</p>
                                    <Link to="/blog/details" className="btn adi-btn-secondary">Read More</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 my-3">
                            <div className="blog-card">
                                <div className="blog-card-image">
                                    <img src="./image/blog5.webp" alt="n/a" className="img-fluid" />
                                </div>
                                <div className="blog-card-text">
                                    <h4>Jagoron Loan</h4>
                                    <div className="post-meta">
                                        <ul className="m-0 p-0 d-flex flex-wrap">
                                            <li className="m-1"><FontAwesomeIcon icon={faUser}/> By admin-test</li>
                                            <li className="m-1"><FontAwesomeIcon icon={faCalendarAlt}/> October 26, 2023</li>
                                            <li className="m-1"><FontAwesomeIcon icon={faComment}/> Comment (0)</li>
                                        </ul>
                                    </div>
                                    <p>Over the past two twenty years ADI has been providing capital under the RMC...</p>
                                    <Link to="/blog/details" className="btn adi-btn-secondary ">Read More</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BlogPage;