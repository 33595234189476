import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import SliderGovernance from "../../components/PageComponents/SliderGovernance";
import { Link } from "react-router-dom";

const GovernancePage = () => {    
    return (
        <>
            <SliderGovernance/>
            <div className="row m-0 p-0">                
                <div className="container">
                    <section className="row governance-section my-5">
                        <div className="col-7 mx-auto">
                            <div className="row py-5">
                                <div className="col-5">
                                    <img src="./image/governance-board4.png" alt="" className="img img-fluid "/>
                                </div>
                                <div className="col-7">
                                    <h3 className="display-3 my-3 adi-text-primary">M. Anisul Islam Chairman</h3>
                                    <div className="strong fw-bold">Alternative Development Initiatives (ADI)</div> 
                                    <div className="p my-3">
                                        Organization has 15 eminent General Assembly Member, they take the decision of annual report, audit report review, approve budget of the organization, including the basic regulations decision of the organization. 5 eminent Members of Management committee amalgamate for the quarterly meeting. Sincere efforts & advice from the organization’s Management Committee, organizations sustainable development is going forward.The Executive Director is responsible for the overall management of the organization. Her task is to assist the central administration, program and accounting department. Regional Office under the supervision of the central office to oversee the activities of the branch level.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="row">
                        <div className="col-8 mx-auto pb-5">
                            <div className="section-header text-center">
                                <h2 className="display-2 governance-title adi-text-primary">Board Member of GSSBL</h2>
                                <span>Need to grow in 2023</span>
                            </div>
                            <div className="governance-card-area">
                                <div className="row p-5">
                                    <div className="col-md-4 col-lg-4 col-sm-12">
                                        <div className="card text-center h-100">
                                            <div className="card-body">
                                                <img src="./image/governance-board1.png" alt="" className="img-fluid"/> 
                                                <h4 className="card-title">
                                                   <Link to={"#0"} className="adi-text-primary"> Ayesha Siddiqa</Link>
                                                </h4>
                                                <div className="card-content">
                                                    Director (Women Entrepenure)
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-lg-4 col-sm-12">
                                        <div className="card text-center h-100">
                                            <div className="card-body">
                                                <img src="./image/governance-board2.png" alt="" className="img-fluid"/> 
                                                <h4 className="card-title">
                                                <Link to={"#0"} className="adi-text-primary">Rokibul Haque</Link> 
                                                </h4>
                                                <div className="">
                                                    Director
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-lg-4 col-sm-12">
                                        <div className="card text-center h-100">
                                            <div className="card-body">
                                                <img src="./image/governance-board3.png" alt="" className="img-fluid"/> 
                                                <h4 className="card-title">
                                                   <Link to="#" className="adi-text-primary">Naznin Akther</Link> 
                                                </h4>
                                                <div className="">
                                                    Director (Additional Professor)
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className="text-center">
                                    <a href="#0" className="btn btn-default border btn-lg">See All</a>
                                </div>   
                            </div>
                        </div>
                    </section>
                </div>
                <section className="py-5 call-2-action-1 text-center">
                    <div className="my-3 fw-bold">Don’t wait any longer</div>
                    <div className="display-4">Alternative Development Initiatives (ADI)</div>
                    <div className="py-3">House: 58 (4th Floor), Road: 03, Block: B, Niketon, Gulshan -01, Dhaka-1212, Bangladesh.</div>
                    <div className="display-6 my-3">
                        <FontAwesomeIcon icon={faPhone} className="me-2"/>
                        02-9861412
                    </div>
                </section>
            </div>
        </>
    );
};

export default GovernancePage;