import React from 'react';
// import { useLocation } from 'react-router-dom';
import Header from './components/global/Header';
import Menu from './components/global/Menu';
import Footer from './components/global/Footer';
import PublicRoutes from './routes/PublicRoutes';
// import AdminRoutes from './routes/AdminRoutes';
import { BrowserRouter as Router } from "react-router-dom";

function App() {
  const projectName = 'ADI - React TypeScript Template';
  const linkUrl = window.location.pathname;
  console.log('_test_of_life', linkUrl)
  return (
    <>
      <Router>
        <div>
          <Header projectName={projectName} />
            <Menu linkUrl={linkUrl} />
            <PublicRoutes />
            <Footer />
        </div>
      </Router>
    </>
  );
}

export default App;
