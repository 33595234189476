import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import HomePage from "../pages/Public/HomePage";
import AboutPage from "../pages/Public/AboutPage";
import ProgramPage from "../pages/Public/ProgramPage";
import PublicationPage from "../pages/Public/PublicationPage";
import GovernancePage from "../pages/Public/GovernancePage";
import ContactPage from "../pages/Public/ContactPage";
import BlogPage from "../pages/Public/BlogPage";


const PublicRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/program" element={<ProgramPage />} />
            <Route path="/publication" element={<PublicationPage />} />                
            <Route path="/governance" element={<GovernancePage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/blog" element={<BlogPage />} />
        </Routes>
    );
};

export default PublicRoutes;