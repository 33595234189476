import React from "react";

const SliderProgram = () => {
    return (
        <>
            <div className="text-center program-slider">
                <div className="center-area">
                    <h2 className="display-2">Our Program</h2>
                    <h4>Alternative Development Initiatives (ADI)</h4>
                </div>
                
                <div className="overlay"></div>
            </div>
        </>
    );
};

export default SliderProgram;